import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const uncri = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Un cri" />
    <h3 className='underline-title'>Un cri</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Abaisse ton bras<br />
        Abrège la durée<br />
        Le poids est lourd<br />
        La durée est longue<br />
        Personne ne saura résister<br />
        Pas même Sysiphe-le-fataliste<br />
        Nous sommes à bout de souffle<br />
      </p>
      <p>
        Dans son lit conjugal l'épouse se profane<br />
        En présence de son père la fille se violente<br />
        Devant sa mère en larmes le fils s'égorge<br />
        Au mépris de tout BACCHUS s'adore<br />
      </p>
      <p>
        Abaisse ton bras<br />
        Abrège la durée<br />
        Personne ne saura s'échapper<br />
        Pas même Spartacus-le-téméraire<br />
        Nous sommes dégonflés<br />
      </p>
      <p>
        La tête adultérée de factices<br />
        Les yeux dilatés d'insomnies<br />
        La bouche élargie de braillements<br />
        La poitrine asphyxiée de pollutions<br />
        Les mains enflammées d'applaudissements<br />
        Le ventre creusé de polyphagies<br />
        Les pieds prostrés de marches<br /> 
      </p>
      <p>
        Abaisse ton bras<br />
        Abrège la durée<br />
        Aucun corps humain ne manquera de s'affaler<br />
        Pas même la stature titanesque d'IDI-DADA<br />
      </p>
      <p>
        Jadis la lance sauvage perçait un ennemi par minute<br />
        Naguère le pistolet mitrailleur sème cent morts à la seconde<br />
        Aujourd'hui les orgues de Staline pulvérisant mille hommes en<br />
        fraction de seconde
      </p>
      <p>
        Abaisse ton bras<br />
        Abrège la durée<br />
        Ne continue pas à frapper<br />
        Personne ne devra survivre<br />
        Pas même Mathusalem-lincrevable<br />
        Nous sommes accablés<br />
      </p>
      <p>
        Pitié! Pitié!<br />
        Clamamus ad Te! (1)<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">(1) Nous crions vers Toi !</p>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default uncri
